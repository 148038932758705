import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import Loading from "./Components/Loading";
import Providers from "./Components/providers";
import Containers from "./Containers";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./utils/fb";
import TagManager from 'react-gtm-module';
// moment.tz.setDefault("Pacific/Auckland");

TagManager.initialize({ gtmId: 'GTM-W2GTWJB' });
ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Route path="/">
            <Containers />
          </Route>
        </BrowserRouter>
      </Suspense>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.register();
