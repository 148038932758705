import { Grid, Typography } from "@material-ui/core";
import React from "react";
import TextInput from "../../../Components/TextInput";
import { IS_MOBILE } from "../../../utils/fb";
import { EventKeys, IEvent } from "../../../utils/types";
import Section from "../Section";
import SubSection from "../SubSection";

interface IProps {
  event: IEvent;
}

function convertMeterToKM(meter: number) {
  console.log(meter)
  if (meter == 0) {
    return ""
  } else {
    var km = (meter * 2) / 1000;
    if (Math.round(km) < 10) {
      return km.toFixed(1) + " km"
    } else {
      return Math.round(km).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " km"
    }
  }
}

function convertSecondToHourAndMins(rawSeconds: number) {
  console.log(rawSeconds)

  var seconds = rawSeconds * 2
  var h = Math.round(seconds / 3600);
  var m = Math.round(seconds % 3600 / 60);

  var hmString = ""
  if (h > 0) {
    if (h == 1) {
      hmString += " " + h + " hour"
    } else {
      hmString += " " + h + " hours"
    }
  }
  if (m > 0) {
    if (m == 1) {
      hmString += " " + m + " min"
    } else {
      hmString += " " + m + " mins"
    }
  }
  return hmString
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: IProps) => {
  return (
    <Section title="Travel">
      <Grid container direction="column" spacing={3}>
        {!IS_MOBILE && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <SubSection title="Travel Distance">
                  <TextInput
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                    disabled
                    value={convertMeterToKM(props.event[EventKeys.DistanceNumber] || 0)}
                  />
                </SubSection>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <SubSection title="Travel Time">
                  <TextInput
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                    disabled
                    value={convertSecondToHourAndMins(props.event[EventKeys.TravelTimeNumber] || 0)}
                  />

                </SubSection>
              </Grid>
              <Typography variant="caption" style={{ paddingLeft: "1%" }}>
                Calculated as a return trip from the central suburbs of selected city
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <SubSection title="Travel Fee" noMobileView>
            <TextInput
              style={{
                backgroundColor: "#fafafa",
              }}
              disabled
              inputProps={{
                style: {
                  fontWeight: "bold",
                  color: "#3d3d3d",
                },
              }}
              value={
                props.event[EventKeys.TravelCost]
                  ? `$${props.event[EventKeys.TravelCost]
                  } per Butler`
                  : props.event[EventKeys.Distance] ? "No travel fee applies" : ""
              }
            />
            <Typography variant="caption" style={{ paddingLeft: "1%" }}>
              Calculated as a return trip from the central suburbs of selected city
            </Typography>
          </SubSection>
        </Grid>
      </Grid>
    </Section>
  );
};
