export const incorrectStageErrorMsg = "Incorrect stage";
export enum STAGE_NAMES {
  SERVICE = "Service",
  EVENT = "Event",
  INFORMATION = "Information",
}

export enum GOOGLE_TRACKER_EVENT_LABEL {
  SERVICE = "01 Your Service",
  EVENT = "02 Your Event",
  INFORMATION = "03 Your Information",
}
