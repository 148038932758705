import { CSSProperties } from "@material-ui/styles";

export const body: CSSProperties = {
  // minHeight: "100vh",
  // width: "100vw",
  // paddingTop: !IS_MOBILE ? "8%" : undefined,
  // paddingRight: !IS_MOBILE ? "20%" : undefined,
  // paddingLeft: !IS_MOBILE ? "20%" : undefined,
  // width:""
};
export const gridBody = {
  width: "100%",
};
